import React from 'react';
import './Stats.css';

const Api = require('../../api/api');
const Header = require('../common/Header');

class Stats extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            results: {}
        }

        this.gameResults = this.gameResults.bind(this);
        this.goBack = this.goBack.bind(this);
    }

    componentWillMount() {
        this.props.updateMusic("/assets/common/audio/music/spaceship.mp3", true);
        Api('GET', 'game-results/', {
            'limit': '10000',
            'student': this.props.studentData.id,
            'nocategory': true
        }, this.gameResults);
    }

    gameResults(data) {
        let results = {
            0: {
                'time': 0,
                'questions': 0,
                'correct': 0,
                'gems': 0,
                'played': 0,
                'title': 'All Games',
            }
        };
        for (let n in data.results) {
            if (data.results.hasOwnProperty(n)) {
                if (typeof results[data.results[n].game_level.game] === 'undefined') {
                    results[data.results[n].game_level.game] = {
                        'time': 0,
                        'questions': 0,
                        'correct': 0,
                        'gems': 0,
                        'played': 0,
                    };
                    let gameInt = parseInt(data.results[n].game_level.game);
                    if (gameInt === 1) {
                        results[gameInt].title = 'Hocus Pocus Attention & Focus';
                    }
                    if (gameInt === 2) {
                        results[gameInt].title = 'Self Control Black Hole';
                    }
                    if (gameInt === 3) {
                        results[gameInt].title = 'Listening Launch';
                    }
                    if (gameInt === 4) {
                        results[gameInt].title = 'Milky Way Memory';
                    }
                    if (gameInt === 5) {
                        results[gameInt].title = 'Thinking Universe';
                    }
                    if (gameInt === 6) {
                        results[gameInt].title = 'Flying Following Directions';
                    }
                    if (gameInt === 7) {
                        results[gameInt].title = 'Pattern Puzzler';
                    }
                }
                let correct = Math.floor(parseInt(data.results[n].total_questions) * parseInt(data.results[n].score) / 100);
                if (parseInt(data.results[n].total_questions) === 1 && parseInt(data.results[n].score) < 100) {
                    correct = 0;
                }

                results[0].time += data.results[n].time_to_complete;
                results[0].questions += data.results[n].total_questions;
                results[0].correct += correct;
                results[0].gems += data.results[n].gem_reward;
                results[0].played++;

                results[data.results[n].game_level.game].time += data.results[n].time_to_complete;
                results[data.results[n].game_level.game].questions += data.results[n].total_questions;
                results[data.results[n].game_level.game].correct += correct;
                results[data.results[n].game_level.game].gems += data.results[n].gem_reward;
                results[data.results[n].game_level.game].played++;
            }
        }
        this.setState({
            results: results
        });
    }

    goBack() {
        this.props.history.goBack();
    }

    render() {
        let self = this;

        return (
            <div id="stats" className="topLeft100">
                <Header
                    appName="stats"
                    inGame={true}
                    goBack={this.goBack}
                    studentData={this.props.studentData}
                />
                <h1>My Stats</h1>
                <div id="npc" />
                <div className="container">
                    <div>
                        {this.state.results[0] && Object.keys(this.state.results).sort().map(function(key){
                            let result = self.state.results[key];
                            let seconds = result.time;
                            let days = Math.floor(seconds / (3600*24));
                            seconds  -= days*3600*24;
                            let hrs   = Math.floor(seconds / 3600);
                            seconds  -= hrs*3600;
                            let mnts = Math.floor(seconds / 60);
                            seconds  -= mnts*60;
                            let timeString = '';
                            if (days) {
                                timeString += days + ' Days';
                            }
                            if (hrs) {
                                if (timeString !== '') {
                                    timeString += ', ';
                                }
                                timeString += hrs + ' Hours';
                            }
                            if (mnts) {
                                if (timeString !== '') {
                                    timeString += ', ';
                                }
                                timeString += mnts + ' Minutes';
                            }
                            if (timeString !== '') {
                                timeString += ', ';
                            }
                            timeString += seconds + ' Seconds';

                            return (
                                <div key={key}>
                                    <h2>{result.title}</h2>
                                    <p>
                                        <span>Times Played:</span>
                                        <span>{result.questions}</span>
                                    </p>
                                    <p>
                                        <span>Questions Answered:</span>
                                        <span>{result.questions}</span>
                                    </p>
                                    <p>
                                        <span>Correct Answers:</span>
                                        <span>{result.correct}</span>
                                    </p>
                                    <p>
                                        <span>Gems Earned:</span>
                                        <span>{result.gems}</span>
                                    </p>
                                    <p>
                                        <span>Time Spent Playing:</span>
                                        <span>{timeString}</span>
                                    </p>
                                </div>
                            );
                        })}
                        {!this.state.results[0] &&
                        <div className="loading" />
                        }
                    </div>
                </div>
            </div>
        );
    }
}

module.exports = Stats;
