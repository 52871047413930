export const Rooms = [
    {
        'path': 'cockpit',
        'name': 'Cockpit'
    },
    {
        'path': 'cargo-bay',
        'name': 'Cargo Bay'
    },
    {
        'path': 'kitchen',
        'name': 'Kitchen'
    },
    {
        'path': 'living-room',
        'name': 'Living Room'
    }
];
