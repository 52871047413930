import React from 'react';

function Loading() {
    return (
        <div className="loading">
            <img src="/assets/common/img/loading.gif"/>
        </div>
    )
}

module.exports = Loading;
