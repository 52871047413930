import React from 'react';
import './Achievements.css';

const Arrow = require('../common/Arrow');
const Header = require('../common/Header');

class Achievements extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: 1,
            pageCount: 1,
        };
        this.goBack = this.goBack.bind(this);
        this.nextPage = this.nextPage.bind(this);
        this.prevPage = this.prevPage.bind(this);
    }

    componentWillMount() {
        this.props.updateMusic("/assets/common/audio/music/spaceship.mp3", true);
    }

    shouldComponentUpdate() {
        return false;
    }

    goBack() {
        this.props.history.goBack();
    }

    nextPage() {
        let next = this.state.currentPage + 1;
        if (next > this.state.pageCount) {
            next = 1;
        }
        let ap = $('#achievementPage');
        ap.find('.page').css('display', 'none');
        ap.find('.page:nth-child(' + next + ')').css('display', 'block');
        this.state.currentPage = next;
    }

    prevPage() {
        let next = this.state.currentPage - 1;
        if (next === 0) {
            next = this.state.pageCount;
        }
        let ap = $('#achievementPage');
        ap.find('.page').css('display', 'none');
        ap.find('.page:nth-child(' + next + ')').css('display', 'block');
        this.state.currentPage = next;
    }

    render() {
        let self = this;
        let achievementsById = this.props.achievementsById;
        let achievements = Object.assign({}, this.props.achievementsById);
        let studentAchievements = this.props.studentData.achievements;
        this.state.pageCount = Math.ceil(Object.keys(achievements).length / 6);
        let pages = [];
        let completeAchievementCount = Object.keys(studentAchievements).length;
        let key = 0;

        if (studentAchievements) {
            for (let a in studentAchievements) {
                if (studentAchievements.hasOwnProperty(a)) {
                    if (achievements.hasOwnProperty(a)) {
                        delete achievements[a];
                    }
                }
            }
        }

        for (let page = 0; page < this.state.pageCount; page++) {
            pages[page] = [];
            for (let i = 0; i < 6; i++) {
                let item = i + (page * 6);
                if (studentAchievements.hasOwnProperty(Object.keys(studentAchievements)[item])) {
                    pages[page][i] = studentAchievements[Object.keys(studentAchievements)[item]];
                    pages[page][i].complete = true;
                } else if (achievements.hasOwnProperty(Object.keys(achievements)[item - completeAchievementCount])) {
                    pages[page][i] = achievements[Object.keys(achievements)[item - completeAchievementCount]];
                    pages[page][i].complete = false;
                }
            }
        }

        return (
            <div id="achievementPage" className="topLeft100">
                <Header
                    appName="achievements"
                    inGame={true}
                    goBack={this.goBack}
                    studentData={this.props.studentData}
                />
                <h1>My Achievements</h1>
                <Arrow action={this.prevPage} direction="left" />
                <Arrow action={this.nextPage} direction="right" />
                <div className="container">
                    {pages.map(function(page){
                        key++;
                        let pageKey = 'page' + key;
                        let className = 'page ' + pageKey;
                        let style = {};
                        if (key === self.state.currentPage) {
                            style.display = 'block';
                        }
                        return (
                            <div className={className} style={style} key={pageKey}>
                                {
                                    page.map(function(a){
                                        let description = '';
                                        let title = '';
                                        let gems = false;
                                        let iconStyle = {};
                                        let itemClass = 'item';

                                        if (achievementsById[a.id].hasOwnProperty('reward_type')) {
                                            if (achievementsById[a.id].reward_type === 'Certificate') {
                                                itemClass = 'item ribbon';
                                            }
                                            if (achievementsById[a.id].reward_type === 'Bronze Medal') {
                                                itemClass = 'item bronzeMedal';
                                            }
                                            if (achievementsById[a.id].reward_type === 'Silver Medal') {
                                                itemClass = 'item silverMedal';
                                            }
                                            if (achievementsById[a.id].reward_type === 'Gold Medal') {
                                                itemClass = 'item goldMedal';
                                            }
                                            if (achievementsById[a.id].reward_type === 'Plaque') {
                                                itemClass = 'item plaque';
                                            }
                                            if (achievementsById[a.id].reward_type === 'Question Mark') {
                                                itemClass = 'item questionMark';
                                                iconStyle = {backgroundImage: 'none'};
                                            }
                                        }

                                        if (achievementsById[a.id].game_name) {
                                            title = achievementsById[a.id].game_name;
                                            if (achievementsById[a.id].game_name === 'Flying Following Directions') {
                                                iconStyle = {backgroundPosition: '-100% 0'};
                                            }
                                            if (achievementsById[a.id].game_name === 'Self Control Black Hole') {
                                                iconStyle = {backgroundPosition: '-200% 0'};
                                            }
                                            if (achievementsById[a.id].game_name === 'Hocus Pocus') {
                                                iconStyle = {backgroundPosition: '-300% 0'};
                                                title = achievementsById[a.id].game_name + ' Attention and Focus';
                                            }
                                            if (achievementsById[a.id].game_name === 'Milky Way Memory') {
                                                iconStyle = {backgroundPosition: '-400% 0'}
                                            }
                                            if (achievementsById[a.id].game_name === 'Pattern Puzzler') {
                                                iconStyle = {backgroundPosition: '-500% 0'}
                                            }
                                            if (achievementsById[a.id].game_name === 'Thinking Universe') {
                                                iconStyle = {backgroundPosition: '-600% 0'}
                                            }
                                        } else {
                                            title = 'All Games';
                                        }

                                        if (achievementsById[a.id].hasOwnProperty('reward_reason')) {
                                            if (achievementsById[a.id].reward_reason === 'Completed Questions') {
                                                description = 'Answer ' + achievementsById[a.id].reward_value + ' ';
                                                if (achievementsById[a.id].difficulty) {
                                                    description += achievementsById[a.id].difficulty;
                                                } else {
                                                    description += 'Total';
                                                }
                                                description += ' Questions!';
                                            }
                                            if (achievementsById[a.id].reward_reason === 'Completed Difficulty') {
                                                description = 'Complete ' + achievementsById[a.id].difficulty + ' Difficulty!';
                                            }
                                            if (achievementsById[a.id].reward_reason === 'Completed Game') {
                                                description = 'Complete ' + achievementsById[a.id].game_name + '!';
                                            }
                                            if (achievementsById[a.id].reward_reason === 'Space Gems') {
                                                gems = true;
                                                title = 'Earn ' + achievementsById[a.id].reward_value + ' Space Gems!';
                                            }
                                        }

                                        return (
                                            <div className="achievement" key={a.id}>
                                                {!gems && a.complete &&
                                                <div className="box">
                                                    <div className={itemClass}>
                                                        <div className="icon" style={iconStyle}/>
                                                    </div>
                                                </div>
                                                }
                                                {gems && a.complete &&
                                                    <div className="box gems" />
                                                }
                                                {!a.complete &&
                                                    <div className="box inactive" />
                                                }
                                                <div className="text">
                                                    <p>{title}</p>
                                                    <p>{description}</p>
                                                </div>
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        )
                    })}
                </div>
            </div>
        );
    }
}

module.exports = Achievements;
