import './Header.css';

const React = require('react');
const PropTypes = require('prop-types');

import {
  Link
} from 'react-router-dom'

function Logo (props) {
    return (
        <Link to="/">
            {props.img ?
            <img src={props.img} /> : null }
        </Link>
    )
}

function Back (props) {
    let backButton = "/assets/"+ props.appName + "/img/back-button.png";
    if (props.appName === 'test-my-brain') {
        return (<div />);
    }
    return (
        <div onClick={props.goBack}>
            <img src={backButton} alt=""/>
        </div>
    )
}
Back.propTypes = {
    goBack: PropTypes.func.isRequired
};

class Gems extends React.Component {
    render() {
        return (
            <div id="gems">
                <p>{this.props.gems}</p>
                <p>Space Gems</p>
            </div>
        )
    }
}

class Header extends React.Component {
    render() {
        return (
            <header>
                {this.props.appName &&
                <div className="back">
                    {!this.props.inGame ?
                        <Link to="/">
                            <img src={"/assets/" + this.props.appName + "/img/back-button.png"}/>
                        </Link>
                        :
                        <Back goBack={this.props.goBack} appName={this.props.appName}/>
                    }
                </div>
                }
                {this.props.gameName && this.props.difficulty && this.props.level &&
                <div className="gameInfo">
                    <div className="bkg"/>
                    <p className="game-name">{this.props.gameName}</p>
                    {this.props.needsAssessment ?
                        <p className="game-level">Placement Test</p>
                        :
                        <p className="game-level">{this.props.difficulty}: Level {this.props.level}</p>
                    }
                </div>
                }
                {this.props.studentData.gems >= 0 &&
                <div className="gems">
                    <Gems gems={this.props.studentData.gems}/>
                </div>
                }
                {!this.inGame && this.props.appName && typeof this.props.watchTutorial === 'function' &&
                    <div className="watchTutorial" onClick={this.props.watchTutorial}>
                        <img src="/assets/common/img/watch-tutorial.png" />
                    </div>
                }
            </header>
        )
    }
}

Header.propTypes = {
    goBack: PropTypes.func.isRequired,
    appName: PropTypes.string.isRequired,
    studentData: PropTypes.object.isRequired,
    logo: PropTypes.string
};

module.exports = Header;
