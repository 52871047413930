const PropTypes = require('prop-types');
import './Arrow.css'

const React = require('react');

function Arrow(props) {
    let className = "arrow " + props.direction;
    return (
        <div onClick={props.action} className={className} />
    )
}

Arrow.propTypes = {
    action: PropTypes.func.isRequired,
    direction: PropTypes.string.isRequired,
    className: PropTypes.string
};

module.exports = Arrow;
