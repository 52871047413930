const PropTypes = require('prop-types');
import './Arrow.css'
import {Howl} from 'howler';

const React = require('react');

class BackgroundMusic extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            audioFile: '',
            volume: 0.1,
        }
    }

    componentWillReceiveProps(props) {
        let self = this;

        if (props.volume !== this.state.volume) {
            this.state.volume = props.volume;
            if (this.state.audio) {
                this.state.audio.volume(props.volume);
            }
        }

        if (typeof props.volume === 'undefined') {
            this.state.volume = 0.1;
        }

        if (!props.playMusic && this.state.audio) {
            this.state.audioFile = '';
            this.state.audio.pause();
            this.state.audio.unload();
        } else if (props.audioFile && props.audioFile !== this.state.audioFile) {
            if (this.state.audio) {
                this.state.audio.pause();
                this.state.audio.unload();
            }

            this.state.audioFile = props.audioFile;
            this.state.audio = new Howl({
                src: [this.state.audioFile],
                onload: function() {
                    self.state.audio.play();
                },
                volume: this.state.volume,
                loop: true
            });
        } else if (props.audioFile === '') {
            this.state.audioFile = '';
            if (this.state.audio) {
                this.state.audio.pause();
                this.state.audio.unload();
            }
        }
    }

    render() {
        return (<div/>)
    }
}

BackgroundMusic.propTypes = {
    audioFile: PropTypes.string.isRequired
};

module.exports = BackgroundMusic;
