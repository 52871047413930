const hostname = window && window.location && window.location.hostname;
let dashboard_url = '';
let website_url = '';
let tmom_api_url = '';
let gogo_api_url = '';
if(hostname === 'app.dev.gogobrain.com') {
    dashboard_url = 'https://dashboard.dev.gogobrain.com/';
    website_url = 'https://dev.gogobrain.com/';
    tmom_api_url = 'https://api.dev.testingmom.com/';
    gogo_api_url = 'https://api.dev.gogobrain.com/';
} else if(hostname === 'app.staging.gogobrain.com') {
    dashboard_url = 'https://dashboard.staging.gogobrain.com/';
    website_url = 'https://staging.gogobrain.com/';
    tmom_api_url = 'https://api.testingmom.com/';
    gogo_api_url = 'https://api.staging.gogobrain.com/';
} else {
    dashboard_url = 'https://dashboard.gogobrain.com/';
    website_url = 'https://gogobrain.com/';
    tmom_api_url = 'https://api.testingmom.com/';
    gogo_api_url = 'https://api.gogobrain.com/';
}

export const WEBSITE_URL = website_url;
export const DASHBOARD_URL = dashboard_url;
export const TMOM_API_URL = tmom_api_url;
export const GOGO_API_URL = gogo_api_url;
