import React from 'react';
const GOGO_API_URL = require('../components/common/Urls').GOGO_API_URL;

function api(method, endpoint, data, callback, errorCallback) {
    let url = GOGO_API_URL;

    let headers = {
        "Content-Type": "application/json",
        'Accept': 'application/json',
    };

    let req = {};
    url = url + endpoint;
    if (method === 'GET') {
        req = {
            method: method,
            headers: headers,
            mode: 'cors',
            credentials: 'include',
        };
        if (Object.keys(data).length === 0 && data.constructor === Object) {
            url += '?limit=1000'
        } else {
            url += '?';
            for (let key in data) {
                if (data.hasOwnProperty(key)) {
                    url += key + '=' + data[key] + '&';
                }
            }
        }
    } else {
        req = {
            method: method,
            body: JSON.stringify(data),
            headers: headers,
            mode: 'cors',
            credentials: 'include',
        }
    }

    fetch(url, req).then(
        function(response) {
            return response.json();
        }).then(function(text) {
            if (callback) {
                callback(text);
            }
        }).catch(function(error) {
            if (errorCallback) {
                errorCallback(error);
            }
        });
}

module.exports = api;
